import env from "@/config/env";
import { usePage } from "@/context/PageContext";
import React, { useEffect, useState } from "react";
// import { useNavigate } from "react-router-dom";

const Index: React.FC = () => {
  // const navigate = useNavigate();
  const { navPage } = usePage();

  const ruleClickHandle = () => {
    // navigate("/rule");
    navPage("rule");
  };

  const mapClickHandle = () => {
    // navigate("/map");
    navPage("map");
  };

  const toggleMusic = () => {
    let audio = document.getElementById("music") as HTMLAudioElement;
    if (audio.paused) {
      audio.play();
      document.querySelector(".play")?.classList.add("playing");
    } else {
      audio.pause();
      document.querySelector(".play")?.classList.remove("playing");
    }
  };

  return (
    <>
      <div className="home-wrapper">
        <img className="bg" src="/images/home_bg.jpg" alt="background" />
        <img className="title" src="/images/title.png" alt="" />
        <img className="name" src="/images/name.png" alt="" />
        <img className="time" src="/images/time.png" alt="" />
        <img className="wines" src="/images/wines.png" alt="" />
        <img className="sign" src="/images/sign_1.png" alt="" />
        <img
          className="sign-text animation"
          src="/images/sign_2.png"
          alt=""
          onClick={mapClickHandle}
        />
        <img
          className="rule"
          src="/images/rule.png"
          alt=""
          onClick={ruleClickHandle}
        />
        <img className="hand animation" src="/images/hand.png" alt="" />
        <img
          className="play playing"
          src="/images/play.png"
          alt=""
          onClick={toggleMusic}
        />
      </div>
    </>
  );
};

export default Index;
