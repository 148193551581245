import apiClient from "./apiClient";

export const listList = async (id: number | string) => {
  const response = await apiClient.get(`/list/list?id=${id}`);
  return response.data;
};

export const listShop = async (id: number | string) => {
  const response = await apiClient.get(`/list/shop?id=${id}`);
  return response.data;
};

export const listReport = async () => {
  const response = await apiClient.get(`/list/report`);
  return response.data;
};
