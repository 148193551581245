import env from "@/config/env";
import React, { useEffect, useState } from "react";
// import { useNavigate } from "react-router-dom";
import { App, Button, Space } from "antd";
import { usePage } from "@/context/PageContext";
import { listShop } from "@/api/dataApi";

const Shop: React.FC = () => {
  // const navigate = useNavigate();
  const { modal } = App.useApp();
  const { page, navPage } = usePage();
  const [data, setData] = useState({
    id: 0,
    area_id: 0,
    name: "",
    intro: "",
    cover: "",
    dish_0: "",
    dish_1: "",
    phone: "",
    address: "",
    latitude: "0.000000",
    longitude: "0.000000",
  });
  useEffect(() => {
    let p = page.split(",");
    listShop(p[1]).then((res) => {
      console.log(res);
      setData(res.data);
    });
  }, []);

  const clickHandle = () => {
    if (data.latitude === data.longitude) {
      modal.error({ title: "导航失败", content: "餐厅没有设置导航数据。" });
    } else {
      window.location.href = `http://uri.amap.com/navigation?to=${data.longitude},${data.latitude}&mode=car&src=nyx_super`;
    }
  };
  return (
    <>
      <div className="shop-page">
        <img className="bg" src="/images/shop_bg.jpg" alt="background" />
        <img
          className="back"
          src="/images/back.png"
          alt="back"
          onClick={() => {
            navPage(`area,${data.area_id}`);
          }}
        />
        <img
          className="absolute shop-title"
          src="/images/shop_title.png"
          alt=""
        />
        <div className="absolute shop-title-text nowrap">{data.name ?? ""}</div>
        <img
          className="absolute shop-cover-bg"
          src="/images/shop_cover_bg.png"
          alt=""
        />
        <img
          className="absolute shop-cover-img"
          src={
            data.cover ? `/uploads/${data.cover}` : `/images/placeholder.png`
          }
          alt=""
        />
        <img
          className="absolute shop-cover-desc"
          src="/images/shop_desc.png"
          alt=""
        />
        <div className="absolute shop-title-desc">{data.intro ?? ""}</div>
        <img
          className="absolute shop-menu"
          src="/images/shop_menu.png"
          alt=""
        />
        <div className="absolute shop-menu-text">菜品展示</div>
        <img
          className="absolute shop-menu-bg"
          src="/images/shop_menu_bg.png"
          alt=""
        />
        <img
          className="absolute shop-menu-1"
          src={
            data.dish_0 ? `/uploads/${data.dish_0}` : `/images/placeholder.png`
          }
          alt=""
        />

        <img
          className="absolute shop-menu-2"
          src={
            data.dish_1 ? `/uploads/${data.dish_1}` : `/images/placeholder.png`
          }
          alt=""
        />

        <img
          className="absolute shop-info"
          src="/images/shop_info.png"
          alt=""
        />
        <div className="absolute shop-info-1">订餐电话：{data.phone}</div>
        <div className="absolute shop-info-2 nowrap">
          餐厅地址：{data.address}
        </div>
        <img
          className="absolute navigation"
          src="/images/navigation.png"
          alt=""
          onClick={clickHandle}
        />
      </div>
    </>
  );
};

export default Shop;
