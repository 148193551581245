import env from "@/config/env";
import React, { useEffect, useState } from "react";
// import { useNavigate } from "react-router-dom";
import { usePage } from "@/context/PageContext";

const Rule: React.FC = () => {
  // const navigate = useNavigate();
  const { navPage } = usePage();
  useEffect(() => {}, []);
  const mapClickHandle = () => {
    // navigate("/map");
    navPage("map");
  };

  return (
    <>
      <div className="rule-page">
        <img className="bg" src="/images/rule_bg.jpg" alt="background" />
        <img
          className="back"
          src="/images/back.png"
          alt="back"
          onClick={() => {
            navPage("index");
          }}
        />
      </div>
    </>
  );
};

export default Rule;
