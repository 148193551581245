import env from "@/config/env";
import React, { useEffect, useState } from "react";
// import { useNavigate } from "react-router-dom";
import { usePage } from "@/context/PageContext";
import { listList } from "@/api/dataApi";

const Area: React.FC = () => {
  const { page, navPage } = usePage();
  const [name, setName] = useState("");
  const [list, setList] = useState<any[]>([]);
  // const navigate = useNavigate();
  useEffect(() => {
    let p = page.split(",");
    listList(p[1]).then((res) => {
      console.log(res);
      setName(res.name);
      setList(res.list);
    });
  }, []);

  const clickHandle = (id: number) => {
    // navigate(`/shop?id=${id}`);
    navPage(`shop,${id}`);
  };

  return (
    <>
      <div className="area-page">
        <img className="bg" src="/images/area.jpg" alt="background" />
        <img
          className="back"
          src="/images/back.png"
          alt="back"
          onClick={() => {
            navPage("map");
          }}
        />
        <img
          className="absolute area-title"
          src="/images/area_title.png"
          alt=""
        />
        <div className="absolute title-text">西安—{name}</div>
        <div className="absolute list-wrapper">
          {list.map((item: any, i: number) => {
            return (
              <React.Fragment key={i}>
                <div
                  className="item"
                  onClick={() => {
                    clickHandle(item.id);
                  }}
                >
                  <img
                    src="/images/area_item_bg.png"
                    alt=""
                    onClick={() => {
                      clickHandle(1);
                    }}
                  />
                  <div className="absolute list-title">{item.name}</div>
                  <img
                    className="absolute list-cover"
                    src={
                      item.cover
                        ? `/uploads/${item.cover}`
                        : `/images/placeholder.png`
                    }
                    alt=""
                  />
                </div>
              </React.Fragment>
            );
          })}
        </div>
      </div>
    </>
  );
};

export default Area;
