import React, { useEffect } from "react";
import ReactDOM from "react-dom/client";
import reportWebVitals from "./reportWebVitals";
// import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Routes from "@/config/routes";
import "@/assets/style/main.less";
import { App as AntdApp, ConfigProvider, theme, Spin } from "antd";

import zhCN from "antd/locale/zh_CN";
// import zhTW from "antd/locale/zh_TW";
// import en from "antd/locale/en_US";
import { PageProvider } from "./context/PageContext";
import { listReport } from "./api/dataApi";

const App: React.FC = () => {
  useEffect(() => {
    var audio = document.getElementById("music") as HTMLAudioElement;
    document.addEventListener(
      "WeixinJSBridgeReady",
      function () {
        audio.play();
      },
      false
    );

    // 访问记录
    listReport();

    const play = function () {
      if (audio.paused) {
        audio.play();
      }
      document.removeEventListener("touchstart", play, false);
    };

    document.addEventListener("touchstart", play, false);
  }, []);

  return (
    <ConfigProvider
      theme={{
        token: {
          borderRadius: 8,
        },
      }}
      locale={zhCN}
    >
      <AntdApp>
        <audio
          src="https://qiniu.cdjoie.com/ljchangyin/mp3/bgm1.mp3?v=1.6"
          loop
          id="music"
          autoPlay
        ></audio>
        <PageProvider>
          <Routes />
        </PageProvider>
        {/* <Router>
          <Routes>
            {routes.map((route, index) => (
              <Route key={index} path={route.path} element={route.element} />
            ))}
          </Routes>
        </Router> */}
      </AntdApp>
    </ConfigProvider>
  );
};

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
