import env from "@/config/env";
import React, { useEffect, useState } from "react";
// import { useNavigate } from "react-router-dom";
import { usePage } from "@/context/PageContext";

const Map: React.FC = () => {
  // const navigate = useNavigate();
  const { navPage } = usePage();
  useEffect(() => {}, []);

  const mapLabelClickHandle = (id: number) => {
    // navigate(`/area?id=${id}`);
    navPage(`area,${id}`);
  };

  return (
    <>
      <div className="map-page">
        <img className="bg" src="/images/map_bg.jpg" alt="background" />
        <img
          className="back"
          src="/images/back.png"
          alt="back"
          onClick={() => {
            navPage("index");
          }}
        />
        <img
          className="absolute map-title"
          src="/images/map_title.png"
          alt="map"
        />
        <img className="absolute map" src="/images/map.png" alt="map" />
        <img className="absolute bottom" src="/images/map_bottom.png" alt="" />
        <img
          className="absolute pointer animation"
          src="/images/pointer.png"
          alt=""
        />
        <img
          className="absolute pointer-subtitle"
          src="/images/pointer_subtitle.png"
          alt=""
        />
        {/* <img
          className="absolute map-label map-1"
          src="/images/map_1.png"
          alt=""
          onClick={() => mapLabelClickHandle(11)}
        /> */}
        <img
          className="absolute map-label map-2"
          src="/images/map_2.png"
          alt=""
          onClick={() => mapLabelClickHandle(9)}
        />
        <img
          className="absolute map-label map-3"
          src="/images/map_3.png"
          alt=""
          onClick={() => mapLabelClickHandle(6)}
        />
        <img
          className="absolute map-label map-4"
          src="/images/map_4.png"
          alt=""
          onClick={() => mapLabelClickHandle(4)}
        />
        <img
          className="absolute map-label map-5"
          src="/images/map_5.png"
          alt=""
          onClick={() => mapLabelClickHandle(3)}
        />
        <img
          className="absolute map-label map-6"
          src="/images/map_6.png"
          alt=""
          onClick={() => mapLabelClickHandle(1)}
        />
        <img
          className="absolute map-label map-7"
          src="/images/map_7.png"
          alt=""
          onClick={() => mapLabelClickHandle(2)}
        />
        {/* <img
          className="absolute map-label map-8"
          src="/images/map_8.png"
          alt=""
          onClick={() => mapLabelClickHandle(10)}
        /> */}
        <img
          className="absolute map-label map-9"
          src="/images/map_9.png"
          alt=""
          onClick={() => mapLabelClickHandle(5)}
        />
        {/* <img
          className="absolute map-label map-10"
          src="/images/map_10.png"
          alt=""
          onClick={() => mapLabelClickHandle(8)}
        /> */}
        {/* <img
          className="absolute map-label map-11"
          src="/images/map_11.png"
          alt=""
          onClick={() => mapLabelClickHandle(7)}
        /> */}
      </div>
    </>
  );
};

export default Map;
